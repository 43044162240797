//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkCircle from "@/assets/check-circle-primary.png";
import SpinnerLoader from "@bit/joshk.vue-spinners-css.spinner-loader";
export default {
  name: "PaymentDialog",
  data: () => ({
    checkCircle
  }),
  components: {
    SpinnerLoader
  },
  props: {
    show: Boolean
  }
};