//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { cancelOrder } from "@debionetwork/polkadot-provider";
export default {
  name: "CancelDialog",
  data: () => ({
    password: "",
    error: "",
    showPassword: false,
    inputPassword: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet
    })
  },
  props: {
    show: Boolean,
    orderId: String
  },
  methods: {
    async cancelOrder() {
      if (this.wallet.isLocked) {
        this.inputPassword = true;
        await this.wallet.decodePkcs8(this.password);
      }
      await cancelOrder(this.api, this.wallet, this.orderId);
      this.password = "";
      this.$emit("cancel");
      this.$emit("close");
    },
    closeDialog() {
      this.inputPassword = false;
      this.$emit("close");
    }
  }
};