//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import LabDetailCard from "../LabDetailCard.vue";
import PaymentDetailCard from "../PaymentDetailCard.vue";
export default {
  name: "OrderCanceled",
  components: {
    LabDetailCard,
    PaymentDetailCard
  },
  created() {
    if (!this.selectedService) this.$router.push({
      name: "customer-payment-history"
    });
  },
  computed: {
    ...mapState({
      selectedService: state => state.testRequest.products,
      detailOrder: state => state.testRequest.order
    })
  }
};