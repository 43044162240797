//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getLocations } from "@/common/lib/api";
import { formatUSDTE } from "@/common/lib/price-format.js";
export default {
  name: "LabDetailCard",
  props: {
    serviceDetail: Object
  },
  data: () => ({
    countries: [],
    formatUSDTE
  }),
  computed: {
    computeAvatar() {
      return this.serviceDetail.serviceImage ? this.serviceDetail.serviceImage : require("@/assets/debio-logo.png");
    }
  },
  async mounted() {
    await this.getCountries();
  },
  methods: {
    async getCountries() {
      const {
        data: {
          data
        }
      } = await getLocations();
      this.countries = data;
    },
    computeCountry(country) {
      return this.countries.filter(c => c.iso2 === country)[0].name;
    }
  }
};